import CarouselSliderClients from "./subcomponents/CarouselSliderClients";
import ClientsGenerator from "./subcomponents/ClientsGenerator";

const settings = {
    autoPlaySpeed: 5000,
    autoPlay: true,
    arrows: true,
    infinite: true,
    transitionDuration: 200,
    showDots: false,
};

const data = [
    {
        image_url: "/media/PB_Client_Deborah.png",
        text: `"Paul versteht es stets Kundenwünsche zu erkennen, umzusetzen und mit Liebe zum Detail auszuarbeiten. Paul ist mein Ass im Ärmel, wenn ich weiß, dass es
                    schnell gehen aber trotzdem perfekt werden muss."`,
        title: `Deborah Woznicki`,
        subtitle: `Influencer Marketing & `,
        subsubtitle: `Project Manager - Black Soup`,
        url: "//www.blacksoup.gg/"
    },
    {
        image_url: "/media/PB_Client_MT.png",
        text: `"Perfekte Arbeit! Bei Paul können wir uns auf Zuverlässigkeit, technische Perfektion und großartigen kreativen Input immer verlassen."`,
        title: `Michael Truckenbrodt`,
        subtitle: `Produzent & Geschäftsführer`,
        subsubtitle: `TIME PRINTS KG`,
        url: "//timeprints.de/"
    },
    {
        image_url: "/media/PB_Client_Beatrice.png",
        text: `"Ich arbeite seit 3 Jahren mit Paul. Er ist sehr konzentriert, schnell und hat ein super Händchen für Musik und Rhythmus. Er ist sehr zuverlässig und
                    arbeitet gern selbständig. Immer wieder gern!"`,
        title: `Beatrice Möller`,
        subtitle: `Autorin & Regisseurin`,
        url: "//beamoeller-film.com/"
    },
    {
        image_url: "/media/PB_Client_Staiy.png",
        text: `"Ich konnte mich immer darauf verlassen, dass selbst wenn ich keine Vorstellung davon hatte wie ein Produkt aussehen muss, damit es mich anständig repräsentiert,
            das Paul es immer wusste. Einfach sehr gute Arbeit."`,
        title: `Staiy`,
        subtitle: `Influencer / Streamer`,
        url: "//staiy.tv/"
    },
    {
        image_url: "/media/PB_Client_Julia.png",
        text: `Comming soon`,
        title: `Julia Jacob`,
        subtitle: `Autorin & Regisseurin`,
        url: "//www.diezweiteseite.de/ueber-uns/team/"
    }
];

const Clients = () => {
    return (
        <>
            <div className="w-full mt-8 mb-24 hidden xl:block 2xl:w-7/12 2xl:mx-auto 3xl:w-6/12">
                <div className="xl:w-full">
                    <div className="pt-12 block">
                        <div className="text-center text-4xl text-gold font-bold">What Clients say</div>
                        <div className="h-full pt-12 flex justify-evenly">
                            <ClientsGenerator data={data}/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-full mt-8 mb-24 xl:hidden">
                    <div className="pt-12 block">
                        <div className="text-center text-4xl text-gold font-bold">What Clients say</div>
                        <div className="h-full pt-12">
                            <CarouselSliderClients data={data} settings={settings}/>
                        </div>
                    </div>
            </div>
        </>
    );
};

export default Clients;