import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
    superLargeDesktop: {
        breakpoint: {max: 4000, min: 3000},
        items: 5
    },
    desktop: {
        breakpoint: {max: 3000, min: 1024},
        items: 3
    },
    tablet: {
        breakpoint: {max: 1024, min: 768},
        items: 2,
    },
    mobile: {
        breakpoint: {max: 768, min: 0},
        items: 1,
    },
};


const CarouselSliderProjects = (props) => {
    return (
        <>
            <Carousel
                responsive={responsive}
                autoPlaySpeed={props.settings.autoPlaySpeed}
                autoPlay={props.settings.autoPlay}
                arrows={props.settings.arrows}
                infinite={props.settings.infinite}
                transitionDuration={props.settings.transitionDuration}
                itemClass={props.settings.itemClass}
                showDots={props.settings.showDots}
            >
                {props.data.map(({image, text, url}, index) => {
                    return (
                        <a href={url} rel="noreferrer" target="_blank">
                            <div key={index} className="mx-12">
                                <img src={image} alt={text} className="-ml-1" style={{width: `100%`, height: `100%`, position: `relative`}}/>
                                <div className="text-gold text-xl lg:text-lg mt-2">{text}</div>
                            </div>
                        </a>
                    );
                })}
            </Carousel>
        </>
    );
};

export default CarouselSliderProjects;