import axios from 'axios';

const Contact = () => {

    const SubmitContactForm = () => {
        const formEmail = document.getElementById("formEmail").value;
        const formContent = document.getElementById("formContent").value;

        const url = "/sendmail.php";

        if (!formEmail || !formContent) {
            document.getElementById("error_form_message").classList.remove("hidden");
            return;
        }

        axios.post(url, {email: formEmail, content: formContent}).then((resp) => {
            if (resp.status === 200) {
                document.getElementById("success_message").classList.remove("hidden");
                document.getElementById("error_message").classList.add("hidden");
                document.getElementById("error_form_message").classList.add("hidden");
            }
        }).catch(e => {
            console.log(e);
            document.getElementById("error_message").classList.remove("hidden");
            document.getElementById("error_form_message").classList.add("hidden");
            document.getElementById("contact-form").remove();
        });

    };

    return (
        <>
            <div className="w-full bg-graypb">
                <div className="w-full xl:w-9/12 2xl:w-5.5/12 px-16 h-full mx-auto mt-24 pb-12">
                    <div className="h-full pt-24">
                        <div className="w-max text-l font-bold text-gold2">Interesse?</div>
                        <div className="w-max mt-4 text-3xl text-xl text-gold mb-6">Contact me</div>
                        <div id="contact_form w-full h-full">
                            <div className="w-full h-full text-right">
                                <div id="error_message" className="text-white w-max mx-auto my-auto hidden">
                                    <div className="text-xl text-gold">Sorry there was an error sending your form.</div>
                                </div>
                                <div id="error_form_message" className="text-white w-max mx-auto my-auto hidden">
                                    <div className="text-xl text-gold">Please fill out the complete form.</div>
                                </div>
                                <div id="success_message" className="text-white w-max mx-auto my-auto hidden">
                                    <div className="text-xl text-gold">Success! Your Message was Sent Successfully.</div>
                                </div>
                                <div id="contact-form">
                                    <div className="w-full h-full">
                                        <span className="text-gold2 text-l font-bold">E-Mail Adresse</span>
                                    </div>

                                    <input type="email" id="formEmail" className="border-gold border-2 w-full h-12 bg-black text-white rounded"/>
                                    <div className="w-full h-full mt-2">
                                        <span className="text-gold2 text-l font-bold">Nachricht</span>
                                    </div>
                                    <textarea id="formContent" className="border-gold border-2 w-full h-48 bg-black text-white rounded text-left pt-0"/>
                                    <button onClick={SubmitContactForm} className="mt-4 cursor-pointer text-gold text-3xl font-medium">Senden</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Contact;